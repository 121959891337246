import { STAGE } from 'src/constants/StageConfig';
import { getCurrentStage } from 'src/utils/StageConfigUtils';
import { ContainerAppName, setRemoteEntryFileUrlWindowAttributes } from '@amzn/employee-support-central-mfe-config';

if (getCurrentStage() === STAGE.PROD) {
  window['FAB_URL'] = 'https://fab.myhr.a2z.com';
  window['WORKFLOW_URL'] = 'https://atoz-khonsu-workflow-rendering-app.amazon.work';
} else {
  window['FAB_URL'] = 'https://beta.fab.myhr.a2z.com';
  window['WORKFLOW_URL'] = 'https://atoz-khonsu-workflow-rendering.integ.amazon.work';
}

setRemoteEntryFileUrlWindowAttributes(window, ContainerAppName.HRSC);

import('./bootstrap');

export {};
