/**
 * Stage name constants
 */
export enum STAGE {
  DEV = 'DEV',
  ALPHA = 'ALPHA',
  BETA = 'BETA',
  GAMMA = 'GAMMA',
  PROD = 'PROD',
}

/**
 * Domain to stage map
 */
export const DOMAIN_TO_STAGE: Record<string, STAGE> = {
  'eagles.team.hrsc.pxt.amazon.dev': STAGE.DEV,
  'alpha.hrsc.pxt.amazon.dev': STAGE.ALPHA,
  'beta.hrsc.pxt.amazon.dev': STAGE.BETA,
  'gamma.hrsc.pxt.amazon.dev': STAGE.GAMMA,
  'hrsc.pxt.amazon.dev': STAGE.PROD,
};

/**
 * Stage to domain map
 */
export const STAGE_TO_DOMAIN: Record<STAGE, string> = {
  [STAGE.DEV]: 'eagles.team.hrsc.pxt.amazon.dev',
  [STAGE.ALPHA]: 'alpha.hrsc.pxt.amazon.dev',
  [STAGE.BETA]: 'beta.hrsc.pxt.amazon.dev',
  [STAGE.GAMMA]: 'gamma.hrsc.pxt.amazon.dev',
  [STAGE.PROD]: 'hrsc.pxt.amazon.dev',
};
